import React from 'react'
import Vehicles from '../components/Vehicles/vehicles'
import Header from '../components/Header/header'
import Footer from '../components/Footer/footer'
import Layout from '../components/layout'
import Helmet from 'react-helmet'

const VehiclesPage = () => (
    <Layout>
        <Helmet
          title="VIP CARS - Rent a Car | Ponuda luksuznih vozila za iznajmljivanje"
          meta={[
            { name: 'description', content: 'VIP Cars - Rent a Car | Pogledajte asortiman naših vozila (auta). Za potpun užitak Vam na raspolaganje stavljamo i profesionalne vozače.  T:+387 62 520 556.' },
          ]} >
        </Helmet>
    <div id="vehicles">
    <div className="header-back"></div>
    <Header/>
   
    <section className="section vehicles">
         <div className="container">

            <Vehicles/>
        </div>
   </section>
   <Footer/>
   </div>
   </Layout>
)

export default VehiclesPage
